








import { DropdownField, FormDef, FormField, FormFieldGroup, FormValue } from '@/app_code/Forms'
import Checkbox from '@/components/UI/Elements/Forms/Checkbox.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'
import { Services } from 'truemarket-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import RetialPropertyDetails from './RetailPropertyDetails.vue'
import IValuationFormStep from '@/components/Valuation/IValuationFormStep'

@Component({
  components: {
    Form
  }
})
export default class IndustrialMultiTenantIndustrialPropertyDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  public collectData (): FormValue[] {
    return (this.$refs.mainForm as Form).GetValues().Fields
  }

  mounted (): void {
    const promises = [
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FloorConstructionType),
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.WallConstructionType),
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RoofConstructionType),
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.IndustrialAccommodationFeatures),
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.IndustrialPropertyFeatures),
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyCondition) // 5
    ]

    Promise.all(promises).then((vals) => {
      this.loading = false

      this.fields = [
        new FormFieldGroup({
          Key: 'MultiTenantIndustrialProperty',
          Title: 'Multi-Tenant Industrial Property',
          ShowTitle: true,
          Fields: [
            new FormField({
              Key: 'Industrial_UseDescription',
              Label: 'Use Description:',
              Placeholder: 'e.g. Distribution warehouse, Industrial Store or Showroom',
              Required: true
            }),
            new DropdownField({
              Key: 'Industrial_GeneralCondition',
              Label: 'General Condition:',
              Placeholder: 'Please Select',
              Items: vals[5].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new FormField({
              Key: 'Industrial_ApproximateBuildYear',
              Label: 'Approximate Building Year:',
              Placeholder: '(eg 1990s)',
              Required: false
            }),
            new FormField({
              Key: 'Industrial_FloorArea',
              Label: 'Total Building Floor Area:',
              Placeholder: '(eg 100m²)',
              Required: false
            }),
            new FormField({
              Key: 'Industrial_WarehouseFloorArea',
              Label: 'Warehouse Floor Area (*approximate):',
              Placeholder: '(eg 100m²)',
              Required: true
            }),
            new FormField({
              Key: 'Industrial_OfficeFloorArea',
              Label: 'Office Floor Area (*approximate):',
              Placeholder: '(eg 100m²)',
              Required: true
            }),
            new FormField({
              Key: 'Industrial_OtherFloorAreas',
              Label: 'Other Areas (*approximate):',
              Placeholder: '(eg 100m²)',
              Required: false
            }),
            new FormField({
              Key: 'Industrial_DesignatedCarParks',
              Label: 'Designated Car Parks:',
              Placeholder: '',
              Required: true,
              Type: 'number'
            })
          ]
        }),
        new FormFieldGroup({
          Key: 'ConstructionDetails',
          Title: 'Construction Details',
          ShowTitle: true,
          Fields: [
            new DropdownField({
              Key: 'Industrial_FloorConstruction',
              Label: 'Floors:',
              Placeholder: 'Please Select',
              Items: vals[0].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Industrial_WallConstruction',
              Label: 'Walls:',
              Placeholder: 'Please Select',
              Items: vals[1].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Industrial_RoofConstruction',
              Label: 'Roof:',
              Placeholder: 'Please Select',
              Items: vals[2].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            })
          ]
        }),
        new FormFieldGroup({
          Key: 'PropertyIncome',
          Title: 'Property Income',
          ShowTitle: true,
          Fields: [
            new FormField({
              Key: 'Industrial_AnnualNetRental',
              Label: 'Current Annual Net Rental:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Industrial_AnnualGrossRental',
              Label: 'Current Annual Gross Rental:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Industrial_AnnualOutgoings',
              Label: 'Current Annual Outgoings:',
              Placeholder: '',
              Required: false
            })
          ]
        }),
        new FormFieldGroup({
          Key: 'Industrial_AccommodationFeatures',
          Title: 'Accommodation Features',
          ShowTitle: true,
          Fields: [
            ...(vals[3].map((v) => new FormField({ Key: 'Industrial_AccommodationFeatures', Value: v.ValueId, Composite: true, Label: v.Label, Type: 'checkbox' })))
          ]
        }),
        new FormFieldGroup({
          Key: 'Industrial_PropertyFeatures',
          Title: 'Property Features',
          ShowTitle: true,
          Fields: [
            ...(vals[4].map((v) => new FormField({ Key: 'Industrial_PropertyFeatures', Value: v.ValueId, Composite: true, Label: v.Label, Type: 'checkbox' })))
          ]
        }),
        new FormField({
          Key: 'Industrial_EstimatedValue',
          Label: 'Estimated Value / Current Book Value:',
          Placeholder: '',
          Required: false
        })
      ]
    })
  }
}
