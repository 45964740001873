








import { DropdownField, FormDef, FormField, FormFieldGroup, FormValue } from '@/app_code/Forms'
import Checkbox from '@/components/UI/Elements/Forms/Checkbox.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { EnumerationValueModel, RequestValuationModel } from 'truemarket-modules/src/models/api'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'
import { Services } from 'truemarket-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import IValuationFormStep from '@/components/Valuation/IValuationFormStep'

@Component({
  components: {
    Form
  }
})
export default class IndustrialVacantLandDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  private enumVals: EnumerationValueModel[][] = []

  public collectData (): FormValue[] {
    return (this.$refs.mainForm as Form).GetValues().Fields
  }

  mounted (): void {
    const promises = [
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FloorConstructionType), // 0
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.WallConstructionType), // 1
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RoofConstructionType), // 2
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.ResidentialPropertyFeatures), // 3
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyCondition), // 4
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.CityViews), // 5
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RiverViews), // 6
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.ElevatedOutlook), // 7
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Pool), // 8
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.TennisCourt), // 9
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.SecureParkingSpaces), // 10
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bedrooms), // 11
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bathrooms), // 12
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Parking), // 13
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.AirConditioningType) // 14
    ]

    Promise.all(promises).then((vals) => {
      this.loading = false

      this.enumVals = vals

      this.fields = [
        new FormFieldGroup({
          Key: 'VacantLandDetails',
          Title: 'Vacant Land',
          ShowTitle: true,
          Fields: [
            new FormField({
              Key: 'Industrial_EstimatedValue',
              Label: 'Estimated Value / Current Book Value:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Industrial_CurrentRental',
              Label: 'Current Rental (if applicable):',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: 'Industrial_Other',
              Label: 'Topography & Use:',
              Placeholder: 'Please provide description of topography & any on-site improvements',
              Required: false,
              Type: 'textarea',
              Rows: '10'
            })
          ]
        })
      ]
    })
  }
}
