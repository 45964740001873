











import { FormDef, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import { Component, Prop, Vue } from 'vue-property-decorator'

import IndustrialWarehouseDetails from './PropertySubTypes/IndustrialWarehouseDetails.vue'
import IndustrialFactoryDetails from './PropertySubTypes/IndustrialFactoryDetails.vue'
import IndustrialServiceIndustryDetails from './PropertySubTypes/IndustrialServiceIndustryDetails.vue'
import IndustrialStrataIndustrialUnitDetails from './PropertySubTypes/IndustrialStrataIndustrialUnitDetails.vue'
import IndustrialMultiTenantIndustrialPropertyDetails from './PropertySubTypes/IndustrialMultiTenantIndustrialPropertyDetails.vue'
import IValuationFormStep from '../../IValuationFormStep'
import IndustrialVacantLandDetails from './PropertySubTypes/IndustrialVacantLandDetails.vue'

@Component({
  components: {
    Form,
    IndustrialWarehouseDetails,
    IndustrialFactoryDetails,
    IndustrialServiceIndustryDetails,
    IndustrialStrataIndustrialUnitDetails,
    IndustrialMultiTenantIndustrialPropertyDetails,
    IndustrialVacantLandDetails
  }
})
export default class IndustrialPropertyDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  warehouseId = '3db668a1-fa6a-49ca-b0af-24b5aec68ca7'
  factoryId = '7a518e19-e8db-4e19-98c6-e48ed5fdb1bb'
  serviceIndustryId = 'b5a1fde3-7b21-4f04-9400-c0b0d314a0e6'
  strataIndustrialUnitId = '719f0126-44ec-4e76-af93-fa66820ed019'
  multiTenantIndustrialPropertyId = 'ba5a3627-afb8-48eb-9945-52c720e74e70'
  vacantLandIndustrialPropertyId = 'c01b0c60-19aa-4373-b84f-a11cb99f9676'

  get isWarehouse (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.warehouseId
  }

  get isFactory (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.factoryId
  }

  get isServiceIndustry (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.serviceIndustryId
  }

  get isStrataIndustrialUnit (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.strataIndustrialUnitId
  }

  get isMultiTenantIndustrialProperty (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.multiTenantIndustrialPropertyId
  }

  get isVacantLandIndustrialProperty (): boolean {
    return this.model.PropertyClassification?.PropertyClassification === this.vacantLandIndustrialPropertyId
  }

  public collectData (): FormValue[] {
    const refs = [
      'IndustrialWarehouseDetails',
      'IndustrialServiceIndustryDetails',
      'IndustrialServiceIndustryDetails',
      'IndustrialStrataIndustrialUnitDetails',
      'IndustrialMultiTenantIndustrialPropertyDetails',
      'IndustrialVacantLandDetails'
    ]
      .map((refName) => this.$refs[refName])
      .filter((ref) => ref)
      .map((ref) => ref as unknown as IValuationFormStep)
      .map((ref) => ref.collectData())

    return ([] as FormValue[]).concat.apply([], refs)
  }
}
