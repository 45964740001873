





































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class VideoRecorder extends Vue {
  stream: MediaStream | null = null

  needsPermissions = false

  get formattedCurrentTime (): string {
    return '0:00'
  }

  get formattedTimeLimit (): string {
    return '3:00'
  }

  get isDone (): boolean {
    return true
  }

  cancel (): void{
    this.$emit('close')
  }

  mounted (): void {
    this.needsPermissions = window.sessionStorage.getItem('tm.vr.hasPermissions') !== 'true'

    if (!this.needsPermissions) this.initVideoStream()
  }

  initVideoStream (): void {
    navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: 'environment' } }).then((stream) => {
      this.stream = stream

      const vidElem = this.$refs.vidElem as HTMLVideoElement

      vidElem.srcObject = stream

      this.needsPermissions = false

      window.sessionStorage.setItem('tm.vr.hasPermissions', 'true')
    })
  }
}
