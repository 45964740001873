











import { FileUploadField, FormDef, FormField, FormValue } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import IValuationFormStep from '../IValuationFormStep'
import ValuationFormStep from './ValuationFormStep.vue'

@Component({
  components: {
    ValuationFormStep,
    Form
  }
})
export default class OptionalInfo extends Vue implements IValuationFormStep {
  @Prop({ required: true })
  private readonly model!: RequestValuationModel

  private isRes!: boolean

  @Watch('model.PropertyClassification.PropertyType')
  async updatePropertyType (): Promise<void> {
    var propertyTypeId = this.model.PropertyClassification?.PropertyType
    this.isRes = (propertyTypeId === '82957b62-379e-468e-8f60-adcf33cf8f2e')
    this.$forceUpdate()
  }

  private fields: FormDef = [
    new FileUploadField({
      Key: 'OptionalInfo_Photos',
      Label: 'Photos (minimum 5) or Video:',
      Placeholder: 'Upload Images',
      Required: false,
      Multiple: true,
      ContentTypes: [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'video/mov',
        'video/mp4',
        'application/zip',
        'image/heic'
      ]
    }),
    new FileUploadField({
      Key: 'OptionalInfo_BuildingPlans',
      Label: 'Building Plans (if available):',
      Placeholder: 'Upload Images or PDFs',
      Required: false,
      Multiple: true,
      ContentTypes: [
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/zip',
        'image/heic'
      ]
    }),
    new FileUploadField({
      Key: 'OptionalInfo_LeaseDocuments',
      Label: 'Lease Documents (if available):',
      Placeholder: 'Upload Images or PDFs',
      Required: false,
      Multiple: true,
      ContentTypes: [
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/zip',
        'image/heic'
      ]
    }),
    new FileUploadField({
      Key: 'OptionalInfo_TenancySchedule',
      Label: 'Tenancy Schedule (if available):',
      Placeholder: 'Upload Images or PDFs',
      Required: false,
      Multiple: true,
      ContentTypes: [
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/zip',
        'image/heic'
      ]
    }),
    new FormField({
      Key: 'OptionalInfo_PropertyListingLink',
      Type: 'textarea',
      Label: 'Property Listing Link (optional):',
      Placeholder: 'e.g. www.realestate.com.au',
      Rows: '5',
      Required: false
    })
  ]

  private fieldsRes: FormDef = [
    new FileUploadField({
      Key: 'OptionalInfo_Photos',
      Label: 'Photos (minimum 5) or Video:',
      Placeholder: 'Upload Images',
      Required: false,
      Multiple: true,
      Capture: true,
      ContentTypes: [
        'image/jpg',
        'image/jpeg',
        'image/png',
        'video/mov',
        'video/mp4',
        'application/zip',
        'image/heic'
      ]
    }),
    new FileUploadField({
      Key: 'OptionalInfo_BuildingPlans',
      Label: 'Building Plans (if available):',
      Placeholder: 'Upload Images or PDFs',
      Required: false,
      Multiple: true,
      ContentTypes: [
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'application/zip',
        'image/heic'
      ]
    }),
    new FormField({
      Key: 'OptionalInfo_PropertyListingLink',
      Type: 'textarea',
      Label: 'Property Listing Link (optional):',
      Placeholder: 'e.g. www.realestate.com.au',
      Rows: '5',
      Required: false
    })
  ]

  public collectData (): FormValue[] {
    return (this.$refs.mainForm as Form).GetValues().Fields
  }
}
