















import Form from '@/components/UI/Elements/Forms/Form.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ValuationFormStep from './ValuationFormStep.vue'
import CommercialPropertyDetails from './PropertyTypes/CommercialPropertyDetails.vue'
import IndustrialPropertyDetails from './PropertyTypes/IndustrialPropertyDetails.vue'
import RetailPropertyDetails from './PropertyTypes/RetailPropertyDetails.vue'
import ResidentialPropertyDetails from './PropertyTypes/ResidentialPropertyDetails.vue'
import RuralPropertyDetails from './PropertyTypes/RuralPropertyDetails.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import IValuationFormStep from '../IValuationFormStep'
import { FormValue } from '@/app_code/Forms'

@Component({
  components: {
    ValuationFormStep,
    Form,
    CommercialPropertyDetails,
    IndustrialPropertyDetails,
    RetailPropertyDetails,
    ResidentialPropertyDetails,
    RuralPropertyDetails
  }
})
export default class PropertyDetails extends Vue implements IValuationFormStep {
  @Prop({ required: true })
  private readonly model!: RequestValuationModel;

  private readonly commercialId = 'd9731c32-c12c-4a9f-b73e-0bb1b6868531'
  private readonly industrialId = '2f478d9a-2ec7-4cd1-a9f3-22ee57d6e7e9'
  private readonly retailId = '8500289e-4570-4d7b-a33c-6dede9c1b419'
  private readonly residentialId = '82957b62-379e-468e-8f60-adcf33cf8f2e'
  private readonly ruralId = '65b23448-f76c-4e8d-b984-892bed5e84d1'

  get isCommercial (): boolean {
    return this.model.PropertyClassification?.PropertyType === this.commercialId
  }

  get isIndustrial (): boolean {
    return this.model.PropertyClassification?.PropertyType === this.industrialId
  }

  get isRetail (): boolean {
    return this.model.PropertyClassification?.PropertyType === this.retailId
  }

  get isResidential (): boolean {
    return this.model.PropertyClassification?.PropertyType === this.residentialId
  }

  get isRural (): boolean {
    return this.model.PropertyClassification?.PropertyType === this.ruralId
  }

  handleValidated (valid: boolean): void {
    this.$emit('validated', valid)
  }

  public collectData (): FormValue[] {
    const refs = [
      'CommercialPropertyDetails',
      'IndustrialPropertyDetails',
      'RetailPropertyDetails',
      'ResidentialPropertyDetails',
      'RuralPropertyDetails'
    ]
      .map((refName) => this.$refs[refName])
      .filter((ref) => ref)
      .map((ref) => ref as unknown as IValuationFormStep)
      .map((ref) => ref.collectData())

    return ([] as FormValue[]).concat.apply([], refs)
  }
}
