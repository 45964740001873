












































import { FormDataset, FormDef, FormField, FormFieldGroup, FormValue } from '@/app_code/Forms'
import { IValidatable, ValidationError, ValidationRuleset } from '@/app_code/Forms/Validation'
import { UIError } from 'truemarket-modules/src/models/ui'
import { HttpError } from 'truemarket-modules/src/services'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DropdownList from './DropdownList.vue'
import Field from './Field.vue'
import Checkbox from '@/components/UI/Elements/Forms/Checkbox.vue'
import Checkboxes from '@/components/UI/Elements/Forms/Checkboxes.vue'
import FileUpload from '@/components/UI/Elements/Forms/FileUpload.vue'
import AddressField from './AddressField.vue'
import FieldErrors from './FieldErrors.vue'

@Component({
  components: {
    DropdownList,
    Field,
    Checkbox,
    Checkboxes,
    FileUpload,
    AddressField,
    FieldErrors
  }
})
export default class Form extends Vue {
  @Prop()
  private readonly validationErrors!: boolean;

  @Prop({ default: () => [] as FormField[] })
  private readonly fields!: FormDef;

  @Prop({ default: null })
  private readonly validator!: IValidatable | null;

  private fieldValues: FormValue[] = [];

  private errors: ValidationError[] = []

  public GetValues (): FormDataset {
    return new FormDataset({
      Fields: this.fieldValues
    })
  }

  @Watch('fields')
  updateFieldValues (): void {
    this.fieldValues = []

    this.fields.forEach((f) => {
      if ('IsGroup' in f && f.IsGroup) {
        (f as FormFieldGroup).Fields.forEach((sf) => {
          if (sf.Type !== 'checkbox') this.fieldValues.push({ FieldId: sf.Id, Key: sf.Key, Value: sf.Value ?? '', IsComposite: sf.Composite })
        })
      } else {
        if ('Type' in f && f.Type !== 'checkbox') this.fieldValues.push({ FieldId: f.Id, Key: f.Key, Value: f.Value ?? '', IsComposite: f.Composite })
      }
    })
  }

  mounted (): void {
    this.updateFieldValues()
  }

  handleFieldInput (field: FormField, value: string): void {
    let f = this.fieldValues.find((fv) => fv.FieldId === field.Id)

    if (f) {
      f.Value = value
    } else {
      f = { FieldId: field.Id, Key: field.Key, Value: value, IsComposite: field.Composite } as FormValue

      this.fieldValues.push(f)
    }

    field.Value = value

    this.$emit('field_updated', field)

    this.validate()
  }

  public validate (): boolean {
    const ds = new FormDataset({ Fields: this.fieldValues })

    const implicitRuleset = new ValidationRuleset([])

    // First we check for any built-in validation
    this.fields.forEach((f) => {
      if ('IsGroup' in f && f.IsGroup) {
        const group = f as FormFieldGroup

        group.Fields.forEach((fld) => {
          const field = fld as FormField

          if (field.Required) implicitRuleset.Rules.push(v => v.GetValue(field.Key).length > 0 ? [] : [new ValidationError(field.Key, `${field.ValidationLabel}`)])
          if (field.Validator) implicitRuleset.Rules.push(field.Validator)
        })
      } else {
        const field = f as FormField

        // CUSTOM ADDRESS CHECK
        if (f.Key === 'AddressDetails_Address') {
          if (field.Required && field.Display) implicitRuleset.Rules.push(v => !v.GetValue(field.Key).includes('@') ? [] : [new ValidationError(f.Key, `${'Please enter valid property address'}`)])
        }

        if (field.Required && field.Display) implicitRuleset.Rules.push(v => v.GetValue(field.Key).length > 0 ? [] : [new ValidationError(f.Key, `${field.ValidationLabel}`)])
        if (field.Validator && field.Display) implicitRuleset.Rules.push(field.Validator)
      }
    })

    if (implicitRuleset.Rules.length) {
      const implicitResult = implicitRuleset.Validate(ds)

      this.errors = implicitResult ?? []

      if (implicitResult && implicitResult.length > 0) {
        this.$emit('validated', false)

        return false
      }
    }

    if (this.validator) {
      const result = this.validator.Validate(new FormDataset({
        Fields: this.fieldValues
      }))

      const valid = result === null || result.length === 0

      this.errors = result ?? []

      this.$emit('validated', valid)

      return valid
    }

    this.$emit('validated', true)

    return true
  }

  // public SetErrorState (error: HttpError, autoError = true): void {
  //   if (autoError) this.Errors = []

  //   if (error.ModelState) {
  //     this.ErrorState = error.ModelState

  //     if (autoError) {
  //       this.Errors.push({
  //         Scheme: 'danger',
  //         Message: 'Please correct the errors highlighted below'
  //       })
  //     }
  //   }
  // }
}
