












import { FormField, FormValue, DropdownField, FormFieldGroup } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { IValuationFormStepModel, RequestValuationContactModel, RequestValuationContactRepModel } from 'truemarket-modules/src/models/api'
import { API, ServiceManager, ServiceType } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import IAsyncStep from '../IAsyncStep'
import IValuationFormStep, { IValuationFormModelBuilderStep } from '../IValuationFormStep'
import ValuationFormStep from './ValuationFormStep.vue'

@Component({
  components: {
    Form,
    ValuationFormStep
  }
})
export default class ContactDetails extends Vue implements IValuationFormStep, IValuationFormModelBuilderStep, IAsyncStep {
  public fields = [
    new FormField({ Key: 'Contact_FirstName', Label: 'First Name:', Placeholder: 'Your first name', Required: true }),
    new FormField({ Key: 'Contact_LastName', Label: 'Last Name:', Placeholder: 'Your last name', Required: true }),
    new FormField({ Key: 'Contact_Email', Label: 'Email:', Placeholder: 'Your email address', Type: 'email', Required: true }),
    new FormField({ Key: 'Contact_Phone', Label: 'Phone:', Placeholder: 'Your phone number', Type: 'number', Required: true }),
    new FormField({ Key: 'Contact_BusinessName', Label: 'Business Name (optional):', Placeholder: 'Your business name', Required: false }),
    new DropdownField({
      Key: 'Representative_RepType',
      Label: 'Representative',
      Placeholder: 'Please Select',
      Items: [{ Label: 'Accountant', Key: 'Accountant' }, { Label: 'Lawyer', Key: 'Lawyer' }, { Label: 'None', Key: 'None' }],
      Required: true
    }),
    new FormFieldGroup({
      Key: 'Representative_Group',
      Display: false,
      Fields: [
        new FormField({ Key: 'Representative_RepFullName', Label: 'Representative Name', Placeholder: 'Representative Name', Required: true }),
        new FormField({ Key: 'Representative_RepEmail', Label: 'Representative Email', Placeholder: 'Representative Email', Required: true }),
        new FormField({ Key: 'Representative_RepPhone', Label: 'Representative Phone', Placeholder: 'Representative Phone', Required: true }),
        new FormField({ Key: 'Representative_RepBusinessName', Label: 'Representative Business Name', Placeholder: 'Representative Business Name', Required: true })
      ]
    })
  ]

  public collectData (): FormValue[] {
    return (this.$refs.form as Form).GetValues().Fields
  }

  handleFieldUpdated (data: FormField): void {
    if (!this.fields) return

    const group: any = this.fields.find((f) => f.Key === 'Representative_Group')
    if (!group) return

    if (data.Key === 'Representative_RepType' && data.Value !== 'None') {
      group.Display = true
      this.changeGroupFieldsRequired(group.Fields, true)
    }

    if (data.Key === 'Representative_RepType' && data.Value === 'None') {
      group.Display = false
      this.changeGroupFieldsRequired(group.Fields, false)
    }
  }

  changeGroupFieldsRequired (fields: any, required: boolean): void {
    if (!fields) return

    fields.forEach((field: any) => {
      field.Required = required
    })
  }

  buildModel (): IValuationFormStepModel | IValuationFormStepModel[] {
    return (this.$refs.form as Form).GetValues().BuildModel<RequestValuationContactModel>()
  }

  async OnBeforeNext (): Promise<boolean> {
    const api = ServiceManager.Require<API>(ServiceType.API)

    const model = (this.$refs.form as Form).GetValues().BuildModel<RequestValuationContactModel>()

    // onst preModel = this.collectData() as any

    const contact = await api.CRM.CreateContact({
      FirstName: model.FirstName,
      LastName: model.LastName,
      Email: model.Email,
      Phone: model.Phone,
      RequestBearerToken: true,
      BusinessName: model.BusinessName,
      Subject: 'TrueMarket Step 4: Your Details'
    }, true)

    this.$emit('got_contact', contact)

    const repModel = (this.$refs.form as Form).GetValues().BuildModel<RequestValuationContactRepModel>()

    if (repModel.RepType !== 'None') {
      const practice = await api.Practices.CreatePractice({
        RepresentativeName: repModel.RepFullName,
        Email: repModel.RepEmail,
        Phone: repModel.RepPhone,
        BusinessName: repModel.RepBusinessName,
        Type: repModel.RepType
      })

      this.$emit('got_practice', practice)
    }

    return true
  }
}
