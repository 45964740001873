











































































import { FormField } from '@/app_code/Forms'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import Checkout from '@/components/UI/Elements/Payment/Checkout.vue'
import { RequestValuationModel } from 'truemarket-modules/src/models/api'
import { API, ServiceManager, Services, ServiceType } from 'truemarket-modules/src/services'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ValuationFormStep from './ValuationFormStep.vue'
import IAsyncStep from '../IAsyncStep'
import { InvoiceModel } from 'truemarket-modules/src/models/api/invoices'
import { GetContactModel } from 'truemarket-modules/src/models/api/crm'
import { GetProductModel } from 'truemarket-modules/src/models/api/products'
import TMLoader from '@/components/UI/TMLoader.vue'

@Component({
  components: {
    ValuationFormStep,
    Form,
    Checkout,
    TMLoader
  }
})
export default class Payment extends Vue implements IAsyncStep {
  @Prop({ required: true })
  private readonly model!: RequestValuationModel

  @Prop({ required: true })
  private readonly collectData!: any

  private api = ServiceManager.Require<API>(ServiceType.API)

  private product: GetProductModel | null = null

  private invoice: InvoiceModel | null = null

  paymentProcessing = false

  private active = false

  private discount = false

  private cardHasFailed = false

  private codeError = false

  private hasVoucher = false

  private voucherName = ''

  coupon = ''

  async mounted (): Promise<void> {
    this.updatePrice()
  }

  handleCancelClick (): void {
    this.active = false
    this.discount = true
  }

  async handleConfirmClick (): Promise<void> {
    if (this.api.Payment.HasVoucher(this.coupon)) {
      this.paymentProcessing = true
      this.active = false

      const tmReferral = localStorage.getItem('tmReferral')
      const coupon = localStorage.getItem('coupon')
      const invoice = await this.api.Product.CreateInvoice(this.product.ProductId, tmReferral, coupon)

      this.$emit('handleVoucherOrder', invoice)
    }
  }

  async handleContinueClick (): Promise<void> {
    this.product.Price = (((parseFloat(this.product.Price) / 1.1) * 0.9) * 1.1).toString()
    this.discount = false
    this.hasVoucher = true
  }

  async handleValidateClick (): Promise<void> {
    if (!this.hasVoucher) {
      if (await this.api.Payment.HasVoucher(this.coupon)) {
        this.codeError = false
        const voucher = await this.api.Payment.GetVoucher(this.coupon)

        this.voucherName = voucher.VoucherName

        if (voucher) {
          this.active = true
        }
      } else {
        if (this.coupon === 'Evolv10' || this.coupon === 'Aquila10') {
          localStorage.setItem(
            'coupon',
            this.coupon
          )
          this.discount = true
        } else {
          this.codeError = true
        }
      }
    }
  }

  // This fuction watches if options are changed which could affect the assessment fee
  @Watch('model.PropertyClassification.PropertyClassification')
  @Watch('model.AssessmentDetails.Purpose')
  async updatePrice (): Promise<void> {
    var productTypeId = this.model.PropertyClassification?.PropertyClassification

    var productPurposeId = this.model.AssessmentDetails?.Purpose

    if (productTypeId && productPurposeId) {
      this.product = await this.api.Product.GetProductByTypeAndPurpose(productTypeId, productPurposeId)
    }
  }

  private fields: FormField[] = [
    new FormField({
      Key: 'AgreeToTOS',
      Type: 'checkbox',
      Value: 'true',
      Label: '<b>I agree to the <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a></b>',
      Placeholder: '',
      ValidationMessage: 'You must agree to the Terms and Conditions to continue',
      Required: true
    })
  ]

  async OnBeforeNext (): Promise<boolean> {
    this.paymentProcessing = true

    const checkout = this.$refs.checkout as Checkout

    const success = await checkout.OnBeforeNext()

    if (success) {
      this.$emit('payment_completed', checkout.GetInvoice())
      this.cardHasFailed = false
    } else {
      this.cardHasFailed = true
      this.paymentProcessing = false
    }

    return success
  }
}
