








































import VideoRecorder from '@/components/VideoRecorder.vue'
import { GetFileModel } from 'truemarket-modules/src/models/api/files'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    VideoRecorder
  }
})
export default class FileUpload extends Vue {
  @Prop()
  private readonly placeholder!: string;

  @Prop({ required: true })
  private readonly label!: string;

  @Prop({ default: '' })
  private readonly value!: string;

  @Prop()
  private readonly multiple!: boolean;

  @Prop()
  private readonly capture!: boolean;

  @Prop()
  private readonly contentTypes!: string[];

  private files: GetFileModel[] = []

  private isCapturing = false

  recordVideo (): void {
    this.isCapturing = true
  }

  humanFileSize (size: number): string {
    var i = Math.floor(Math.log(size) / Math.log(1024))

    return ((size / Math.pow(1024, i)).toFixed(2) as unknown as number) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }

  private fileInput: HTMLInputElement | null = null

  pickFiles (): void {
    if (this.fileInput === null) {
      this.fileInput = document.createElement('input')
      this.fileInput.type = 'file'
      if (this.contentTypes.length) this.fileInput.accept = this.contentTypes.join(',')
      if (this.multiple) this.fileInput.multiple = true
      if (this.capture) this.fileInput.setAttribute('capture', 'environment')

      this.fileInput.onchange = this.handleFilesChanged
    }

    this.fileInput.click()
  }

  async handleFilesChanged (): Promise<boolean> {
    if (this.fileInput === null) return false

    const newfiles = this.fileInput.files

    if (!newfiles) return false

    for (let i = 0; i < newfiles.length; i++) {
      const file = newfiles[i]

      const tmFile = await Services.API.Files.Upload(file)

      this.files.push(tmFile)
    }

    this.fileInput.value = ''

    this.$emit('input', this.files.map(f => f.FileId).join(','))

    return true
  }

  removeFile (idx: number): void {
    this.files.splice(idx, 1)
  }
}
