










import { DropdownField, FormDef, FormField, FormFieldGroup, FormValue } from '@/app_code/Forms'
import Checkbox from '@/components/UI/Elements/Forms/Checkbox.vue'
import Form from '@/components/UI/Elements/Forms/Form.vue'
import { EnumerationValueModel, RequestValuationModel } from 'truemarket-modules/src/models/api'
import EnumValueType from 'truemarket-modules/src/models/api/enumerations/EnumValueType'
import { Services } from 'truemarket-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import IValuationFormStep from '@/components/Valuation/IValuationFormStep'

@Component({
  components: {
    Form
  }
})
export default class RuralCroppingFarmDetails extends Vue implements IValuationFormStep {
  fields: FormDef | null = null

  outBuildingFeilds: FormDef | null = null

  propertyFeaturesFeilds: FormDef | null = null

  loading = true

  errored = false

  @Prop()
  private readonly model!: RequestValuationModel

  private enumVals: EnumerationValueModel[][] = []

  public collectData (): FormValue[] {
    const refs = [
      'form1',
      'form2',
      'form3'
    ]
      .map((refName) => this.$refs[refName])
      .filter((ref) => ref)
      .map((ref) => ref as Form)
      .map((ref) => ref.GetValues().Fields)

    return ([] as FormValue[]).concat.apply([], refs)
  }

  mounted (): void {
    const promises = [
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FloorConstructionType), // 0
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.WallConstructionType), // 1
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RoofConstructionType), // 2
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RuralPropertyFeatures), // 3
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyCondition), // 4
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bedrooms), // 5
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bathrooms), // 6
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Outbuildings), // 7
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Dams), // 8
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bores), // 9
      Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FencedPaddocks) // 10
    ]

    Promise.all(promises).then((vals) => {
      this.loading = false

      this.enumVals = vals

      this.fields = [
        new FormFieldGroup({
          Key: 'CroppingFarm',
          Title: 'Cropping Farm',
          ShowTitle: true,
          Fields: [
            new FormField({
              Value: '',
              Key: 'Rural_RuralUse',
              Label: 'Use:',
              Placeholder: 'e.g. Grain crop, Orchard, Horticulture',
              Required: true
            }),
            new DropdownField({
              Key: 'Rural_GeneralCondition',
              Label: 'Condition:',
              Placeholder: 'Please Select',
              Items: vals[4].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Value: '',
              Key: 'Rural_NumberOfBedrooms',
              Label: 'Bedrooms:',
              Placeholder: 'Please Select',
              Items: vals[5].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Value: '',
              Key: 'Rural_NumberOfBathrooms',
              Label: 'Bathrooms:',
              Placeholder: 'Please Select',
              Items: vals[6].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new FormField({
              Key: 'Rural_FloorArea',
              Label: 'Internal Floor Area:',
              Placeholder: 'e.g. 100 m²',
              Required: false
            })
          ]
        }),
        new FormFieldGroup({
          Key: 'ConstructionDetails',
          Title: 'Construction Details',
          ShowTitle: true,
          Fields: [
            new DropdownField({
              Key: 'Rural_FloorConstruction',
              Label: 'Floors:',
              Placeholder: 'Please Select',
              Items: vals[0].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Rural_WallConstruction',
              Label: 'Walls:',
              Placeholder: 'Please Select',
              Items: vals[1].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new DropdownField({
              Key: 'Rural_RoofConstruction',
              Label: 'Roof:',
              Placeholder: 'Please Select',
              Items: vals[2].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            })
          ]
        })
      ]
      this.outBuildingFeilds = [
        new FormField({
          Key: 'Rural_NumberOfOutbuildings',
          Label: 'Outbuildings:',
          Placeholder: '',
          Type: 'number',
          Required: true
        })
      ]
      this.propertyFeaturesFeilds = [
        new FormFieldGroup({
          Key: 'Rural_PropertyFeatures',
          Title: 'Property Features',
          ShowTitle: true,
          Fields: [
            new DropdownField({
              Value: '',
              Key: 'Rural_NumberOfDams',
              Label: 'Number of Dams:',
              Placeholder: 'Please Select',
              Items: vals[8].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            }),
            new FormField({
              Value: '',
              Key: 'Rural_TotalDamCapacity',
              Label: 'Total Dam Capacity (ML):',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Value: '',
              Key: 'Rural_HectaresIrrigated',
              Label: 'Hectares Irrigated:',
              Placeholder: '',
              Required: false
            }),
            new DropdownField({
              Value: '',
              Key: 'Rural_NumberOfOperationalBores',
              Label: 'Number of Operational Bores:',
              Placeholder: 'Please Select',
              Items: vals[9].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: true
            })
          ]
        })
      ]
    })
  }

  mutateGroup (fields: FormDef, groupPrefix: string, desiredNumber: number, groupCreator: (idx: number, groupKey: string) => FormFieldGroup): void {
    if (!fields) return

    if (isNaN(desiredNumber)) return

    const existingCount = fields.filter((f) => f.Key.startsWith(groupPrefix)).length
    const diff = desiredNumber - existingCount

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        fields.push(groupCreator(existingCount + i, `${groupPrefix}_${existingCount + i}`))
      }
    } else {
      var absDiff = Math.abs(diff)

      for (let i = 0; i < absDiff; i++) {
        const idx = fields.map((f) => f.Key.startsWith(groupPrefix)).lastIndexOf(true)

        if (idx !== -1) fields.splice(idx, 1)
      }
    }
  }

  handleFieldUpdated (data: FormField): void {
    if (!this.outBuildingFeilds) return

    if (data.Key === 'Rural_NumberOfOutbuildings') {
      const num = parseInt(data.Value as string, 10)

      this.mutateGroup(this.outBuildingFeilds, 'OutbuildingsDetails_', num, (i, groupKey) => {
        return new FormFieldGroup({
          Key: groupKey,
          Title: `Rural Outbuilding ${i + 1}`,
          ShowTitle: true,
          Fields: [
            new FormField({
              Key: `Rural_OutbuildingsDetails_${i}_BuildingType`,
              Label: 'Building Type/Name:',
              Placeholder: '',
              Required: false
            }),
            new FormField({
              Key: `Rural_OutbuildingsDetails_${i}_BuildingUse`,
              Label: 'Building Use:',
              Placeholder: '',
              Required: false
            }),
            new DropdownField({
              Key: `Rural_OutbuildingsDetails_${i}_BuildingCondition`,
              Label: 'General Condition:',
              Placeholder: 'Please Select',
              Items: this.enumVals[4].map((v) => ({ Label: v.Label, Key: v.ValueId })),
              Required: false
            }),
            new FormField({
              Key: `Rural_OutbuildingsDetails_${i}_BuildingArea`,
              Label: 'Building Area:',
              Placeholder: '',
              Required: false
            })
          ]
        })
      })
    }
  }
}
